const styles = {
  colors: {
    primary: '#ffcb05',
    secondary: '#003a70',
    // pokemon type colors. One for background, one for border
    normal: '#A8A878',
    normalBorder: '#6D6D4E',
    fighting: '#C03028',
    fightingBorder: '#7D1F1A',
    flying: '#A890F0',
    flyingBorder: '#6D5E9C',
    poison: '#A040A0',
    poisonBorder: '#682A68',
    ground: '#E0C068',
    groundBorder: '#927D44',
    rock: '#B8A038',
    rockBorder: '#786824',
    bug: '#A8B820',
    bugBorder: '#6D7815',
    ghost: '#705898',
    ghostBorder: '#493963',
    steel: '#B8B8D0',
    steelBorder: '#787887',
    fire: '#F08030',
    fireBorder: '#9C531F',
    water: '#6890F0',
    waterBorder: '#445E9C',
    grass: '#78C850',
    grassBorder: '#A7DB8D',
    electric: '#F8D030',
    electricBorder: '#A1871F',
    psychic: '#F85888',
    psychicBorder: '#A13959',
    ice: '#98D8D8',
    iceBorder: '#638D8D',
    dragon: '#7038F8',
    dragonBorder: '#4924A1',
    dark: '#705848',
    darkBorder: '#49392F',
    fairy: '#EE99AC',
    fairyBorder: '#9B6470'
  },
  fonts: {
    body: 'Roboto, sans-serif'
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem'
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem'
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em'
  },
  space: {
    px: '1px',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    60: '15rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem'
  }
};

export default styles;
